
import searchModel from './searchModel';
import html from './../objects/html';
import common from './../common';

const SearchModel = searchModel.SearchModel;
const templates = common.sidebarTemplates;
const URI = common.URI;


export const initInstance = function(el) {

	// get elems

	const titleContainer = document.querySelector('.js-sidebarView__title');
	const statusContainer = document.querySelector('.js-sidebarView__status');
	const spinnerContainer = document.querySelector('.js-sidebarView__loading');
	const filtersContainer = document.querySelector('.js-sidebarView__filters');
	const filterStatusContainer = document.querySelector('.js-sidebarView__filter-status');
	const jobsContainer = document.querySelector('.js-sidebarView__jobs');
	const clearFiltersButtons = document.querySelectorAll('.js-sidebarView__clear-filters');

	const messagesContainer = document.querySelector('.js-sidebarView__message');
	const messages = [...document.querySelectorAll('.js-sidebarView__message .m-message-box')];
	const messagesByName = messages.reduce((map, item) => {
		const name = item.getAttribute('data-name')
		if (name) map[name] = item
		return map
	}, {});

	const FILTERS = JSON.parse(filtersContainer.getAttribute('data-filters'));

	const searchModel = new SearchModel({
		fields : { 
			search : document.querySelector('.js-sidebarView__search'),
			jobFamily : document.querySelectorAll('.js-sidebarView__jobFamily'), 
			location : document.querySelectorAll('.js-sidebarView__location'),
			jobType : document.querySelectorAll('.js-sidebarView__jobType'),
			timeType : document.querySelectorAll('.js-sidebarView__timeType'),
		},
		initialData : {
			perPage : 8,
			isLoading : true
		},
		searchUrlBlacklist : ['perPage'],
		FILTERS : FILTERS
	});

	searchModel.onChange(function(data) {
		renderTitle(data, titleContainer, searchModel);
		renderFilters(data, filtersContainer);
		renderStatus(data, statusContainer);
		renderSpinner(data, spinnerContainer);
		const hasMessage = renderMessages(data, messagesByName, messagesContainer);
		renderJobs(data, jobsContainer, searchModel, hasMessage);
		renderFilterStatus(data, filterStatusContainer, searchModel);
		renderClearFiltersButton(data, clearFiltersButtons);
	});

	searchModel.runChangeHandlers();
	searchModel.getJobs();

	function clearFilters() {
		searchModel.set('jobFamily', [], false);
		searchModel.set('location', [], false);
		searchModel.getJobs();
		searchModel.runChangeHandlers();
	}
	[...clearFiltersButtons].forEach((button) => button.addEventListener('click', clearFilters));

	
}


function renderSpinner(data, container) {
	const dom = html.parse(templates.sidebarSpinner(data));
	html.empty(container);
	container.appendChild(dom);
}


function renderTitle(data, container, searchModel) {

	const selectedJobFamily = searchModel.getSelectedJobFamilies()[0] || {};
	const selectedJobFamilyName = selectedJobFamily.Singular_Name || selectedJobFamily.Name || "";

	const selectedLocation = searchModel.getSelectedLocations()[0] || {};
	const selectedLocationName = selectedLocation.Name || "";

	const roleText = selectedJobFamily.Role_Text && selectedJobFamily.Role_Text.toLowerCase() || "role";

	const hasJobs = (data.response && data.response.data && data.response.data.length);
	const isLoading = data.isLoading;

	const jobFamilyText = selectedJobFamilyName ? `<strong>${selectedJobFamilyName}</strong>` : '';
	const locationText = selectedLocationName ? ` in <strong>${selectedLocationName}</strong>` : '';
	const jobsText = `Latest ${jobFamilyText} ${roleText}s ${locationText}`;
	const noJobsText = `No ${jobFamilyText} ${roleText}s found ${locationText}`
	
	const titleData = {
		text : isLoading ? `Loading…` : (jobFamilyText || locationText) ? jobsText : `<strong>Latest ${roleText}s</strong>`,
	}

	const dom = html.parse(templates.sidebarTitle(titleData));
	html.empty(container);
	container.appendChild(dom);
}

function renderFilters(data, container) {
	container.style.display = data.isLoading && !data.response ? 'none' : 'block';
}

function renderClearFiltersButton(data, buttons) {
	if (data.jobFamily.length || data.location.length) {
		[...buttons].forEach((button) => button.style.display = 'block');
	} else {
		[...buttons].forEach((button) => button.style.display = 'none');
	}
}

function renderStatus(data, container) {
	const hasPagination = (data.response.pagination && data.response.pagination.firstItemOnPage);
	container.style.display = (data.isLoading || !hasPagination) ? 'none' : 'block';
	const dom = html.parse(templates.sidebarStatus(data));
	html.empty(container);
	container.appendChild(dom);
}

function renderMessages(data, messages, container) {

	if (data.isLoading) {
		container.style.display = 'none';
		// don't render when loading
		return false;
	} else {
		container.style.display = 'block';
	}

	Object.values(messages).forEach(el => {
		el.style.display = 'none'
	})

	let count = 0
	data.jobFamily.forEach(team => {
		if (messages[team]) {
			messages[team].style.display = 'block'
			count++
		}
	})

	return !!count;
}

function renderJobs(data, container, searchModel, hasMessage) {

	if (data.isLoading) {
		container.style.display = 'none';
		// don't render when loading
		return false;
	} else {
		container.style.display = 'block';
	}

	const pagination = !data.isLoading && data.response.pagination || false;
	const jobCount = !data.isLoading && pagination && pagination.totalItems || 0;

	const selectedJobFamily = searchModel.getSelectedJobFamilies()[0] || {};
	const selectedJobFamilyName = selectedJobFamily.Singular_Name || selectedJobFamily.Name || "";

	const selectedLocation = searchModel.getSelectedLocations()[0] || {};
	const selectedLocationName = selectedLocation.Name || "";

	const roleText = selectedJobFamily.Role_Text && selectedJobFamily.Role_Text.toLowerCase() || "role";

	const jobFamilyText = selectedJobFamilyName ? ` ${selectedJobFamilyName} ` : '';
	const locationText = selectedLocationName ? ` in ${selectedLocationName}` : '';
	const s = jobCount === 1 ? '' : 's';
	const buttonText = (jobCount <= 8) ? `View all ${jobFamilyText} ${roleText}s${locationText}` : `View all ${jobCount} ${jobFamilyText} ${roleText}${s} ${locationText}`;

	const jobs = data.response.data || [];

	const dom = html.parse(templates.sidebarJobs({
		jobs : jobs.map(function(job) {
			job.Locations_Text = job.Virtual_Location_Names_Array.join(', ');
			job.Job_Families_Text = job.Virtual_Job_Family_Names_Array.join(', ');
			return job;
		}),
		buttonText,
		searchUrl : data.searchUrl,
		jobFamilyText,
		locationText
	}));

	html.empty(container);

	if (!jobs.length && hasMessage) return
	container.appendChild(dom);
}

function renderFilterStatus(data, container, searchModel) {

	const selectedJobFamily = searchModel.getSelectedJobFamilies()[0] || {};
	const roleText = selectedJobFamily.Role_Text && selectedJobFamily.Role_Text.toLowerCase() || "role";

	const number = data.response && data.response.pagination && data.response.pagination.totalItems;
	const dom = html.parse(templates.sidebarFilterStatus({
		isLoading : data.isLoading,
		number : number,
		roles : (number === 1) ? roleText : `${roleText}s`
	}));
	html.empty(container);
	container.appendChild(dom);
}